export const appDatas = {
   title: "Simple Stopwatch",
   intro: "Simple easy-to-use stopwatch !",
   nav: [
      {
         name: 'Home',
         url: '/'
      },
      {
         name: 'GitHub',
         url: 'https://github.com/naydogdu/simple-chrono'
      },
   ]
}